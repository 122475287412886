import React, { useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import dropdownIcon from "../../../assets/images/dropdown.svg";
import {
  addChildrenAge,
  decreaseAdults,
  decreasePets,
  increaseAdults,
  increaseChildren,
  increasePets,
  openPersonDropdownHandler,
  removeChildrenAge,
  setIsPersonDropdownActive,
} from "../../../_actions/searchActions";

const QuantityNav = ({ min, value, onIncrease, onDecrease }) => (
  <div className='quantity-nav'>
    <div
      className='quantity-button quantity-plus d-flex align-items-center justify-content-center'
      onClick={onIncrease}
    >
      <FaChevronRight aria-hidden='true' />
      {/* <span className="sr-only">Increase quantity</span> */}
    </div>
    <div
      className='quantity-button quantity-minus d-flex align-items-center justify-content-center'
      onClick={() => value > min && onDecrease()}
    >
      <FaChevronLeft aria-hidden='true' />
      {/* <span className="sr-only">Decrease quantity</span> */}
    </div>
  </div>
);

const FormRow = ({ children }) => <div className='formRow'>{children}</div>;

const LabelWrap = ({ label, description }) => (
  <div className='labelWrap'>
    <span className='label'>{label}</span>
    {description && <span className='description'>{description}</span>}
  </div>
);

function Dropdown({ forView }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { persons, isPersonDropdownActive: dropdownActive } = useSelector(
    (state) => state.search
  );
  const dropdownActiveRef = useRef(dropdownActive);

  useEffect(() => {
    const bodyClass = "dropdown-open";
    if (dropdownActive) document.body.classList.add(bodyClass);
    else document.body.classList.remove(bodyClass);
    return () => document.body.classList.remove(bodyClass);
  }, [dropdownActive]);

  const handleChange = (event, index) => {
    dispatch(addChildrenAge(index, event.target.value));
  };
  useEffect(() => {
    dropdownActiveRef.current = dropdownActive;
  }, [dropdownActive]);

  const dropdownRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const handleClickOutside = (event) => {
    // console.log("Clicked element: ", event.target);
    // console.log("Is inside dropdown: ", dropdownRef.current.contains(event.target));

    if (
      dropdownActiveRef.current &&
      dropdownRef.current &&
      event.target.closest(".customDropdown.numberOfPerson") === null &&
      toggleButtonRef.current &&
      !toggleButtonRef.current.contains(event.target)
    ) {
      dispatch(setIsPersonDropdownActive(false));
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownActive]);

  return (
    <div
      className={`formGroup dropdownWrap mb0 ${
        dropdownActive ? "dropdown-active" : ""
      }`}
    >
      <span
        ref={toggleButtonRef}
        className='dropdownButton'
        onClick={() => {
          dispatch(openPersonDropdownHandler());
        }}
      >
        {(persons.adults > 0
          ? persons.adults + " " + t("adult")
          : t("number_of_person")) +
          (persons.children > 0
            ? ", " + persons.children + " " + t("children")
            : "") +
          (persons.pets > 0 ? ", " + persons.pets + " " + t("Pet(s)") : "")}
      </span>

      <div
        className='customDropdown numberOfPerson'
        ref={dropdownRef}
        style={{ zIndex: "10000" }}
      >
        {forView === "mobile" && (
          <h2 className='blockLable'>
            {t("number_of_person")}
            <span
              className='icnClosed'
              onClick={(event) => {
                event.stopPropagation(); // prevent event from bubbling up
                dispatch(setIsPersonDropdownActive(false));
              }}
            >
              <i className='fa-solid fa-xmark'></i>
            </span>
          </h2>
        )}
        <FormRow>
          <LabelWrap label={t("adult")} description={t("atleast_age")} />
          <div className='inputWrap'>
            <div className='quantity'>
              <input type='number' min='1' value={persons.adults} readOnly />
              <QuantityNav
                min={1}
                value={persons.adults}
                onIncrease={() => dispatch(increaseAdults())}
                onDecrease={() => dispatch(decreaseAdults())}
              />
            </div>
          </div>
        </FormRow>
        {/* <FormRow>
          <LabelWrap label={t("children")} description={t("under_age")} />
          <div className='inputWrap'>
            <div className='quantity'>
              <input type='number' min='0' value={persons.children} readOnly />
              <QuantityNav
                min={0}
                value={persons.children}
                onIncrease={() => dispatch(increaseChildren())}
                onDecrease={() => dispatch(removeChildrenAge())}
              />
            </div>
          </div>
        </FormRow> */}
        {/* {Array.from({ length: persons.children }).map((_, index) => (
          <FormRow key={index}>
            <LabelWrap label={t("children_age")} description={t("enter_age")} />
            <div className='inputWrap'>
              <input
                value={persons.ageOfChildren[index] || ""}
                onChange={(event) => handleChange(event, index)}
                type='number'
                className='formControl'
                placeholder={t("age")}
                name={`age-${index}`}
              />
            </div>
          </FormRow>
        ))} */}
        {/* <FormRow>
          <LabelWrap label={t("pet")} />
          <div className='inputWrap'>
            <div className='quantity'>
              <input type='number' min='0' value={persons.pets} readOnly />
              <QuantityNav
                min={0}
                value={persons.pets}
                onDecrease={() => dispatch(decreasePets())}
                onIncrease={() => dispatch(increasePets())}
              />
            </div>
          </div>
        </FormRow> */}
      </div>
      <img src={dropdownIcon} className='dropdownArrow' alt='dropdown' />
    </div>
  );
}

export default Dropdown;
