import React, { useCallback, useEffect, useRef, useState } from "react";

import unionIcon from "../../../assets/images/Union.svg";
import unionIcon01 from "../../../assets/images/union01.svg";
import SearchBar from "../HomePage/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequisites } from "../../../_actions/coreActions";
import { useParams } from "react-router-dom";
import moment from "moment";
import { submitDetailsForm } from "../../../api";
import { FiAlertCircle } from "react-icons/fi";
import { Elements, CardNumberElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../partials/CheckoutForm";
import Spinner from "../../partials/Spinner";
import SuccessPage from "./SuccessPage";
import { formatMoney, getTenantDomain } from "../../../helper";
import { useTranslation } from "react-i18next";
import PropertyImage from "../HomePage/Gallery";
import { min, parseISO } from "date-fns";
import VoucherInput from "../../partials/Voucher";
import {
  setArrivalDate,
  setDepartureDate,
} from "../../../_actions/searchActions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import axios from "axios";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { getDate } from "date-fns";

const FormPage = () => {
  const [stripe, setStripe] = useState(null);
  const [error, setError] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [formError, setFormError] = useState({});
  const [elements, setElements] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBooked, setIsBooked] = useState(false);
  const params = useParams();
  const [isChecked, setChecked] = useState(false);
  const [disabledDates, setdisabledDates] = useState([]);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherData, setVoucherData] = useState(null);
  const MySwal = withReactContent(Swal);
  const [DisabledDates, setDisabledDates] = useState([]);
  const [minimumStayDates, setMinimumStayDates] = useState([]);
  const [departureDisabledDates, setDepartureDisabledDates] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchKeyAndInitializeStripe() {
      try {
        const res = await fetch(
          `${getTenantDomain(params.tenant)}/api/get-stripe-key`
        );
        if (!res.ok) {
          throw new Error("Failed to fetch Stripe key");
        }
        const data = await res.json();
        const stripe = await loadStripe(data.key, {
          stripeAccount: data.stripe_account_id,
        });
        setStripe(stripe);
      } catch (err) {
        setError(err.message);
      }
    }
    fetchKeyAndInitializeStripe();
  }, []);

  useEffect(() => {
    setDisabledDates(disabledDates);
  }, [disabledDates]);

  useEffect(() => {
    async function getBookedDates() {
      try {
        const res = await fetch(
          `${getTenantDomain(params.tenant)}/api/iframe/get-booked-dates/${
            params.id
          }`
        );
        if (!res.ok) {
          throw new Error("Failed to fetch Stripe key");
        }
        const data = await res.json();

        setdisabledDates(
          data.disabledDates.map((ele) => parseISO(ele.date)) // Convert to date format
        );
        setDepartureDisabledDates(
          data.disabledDates.map((ele) => parseISO(ele.date))
        );
        setMinimumStayDates(data.minimumStayDates);
      } catch (err) {
        setError(err.message);
      }
    }

    getBookedDates();
  }, []);

  const upsells = useSelector((state) => state.core.upsells);
  const currency = useSelector((state) => state.core.currency);

  const dispatch = useDispatch();
  const { arrivalDate, departureDate, persons, selectedProperty } = useSelector(
    (state) => state.search
  );

  useEffect(() => {
    dispatch(fetchRequisites(params.tenant));
  }, [dispatch]);

  const [total, setTotal] = useState(0);
  const [priceStay, setPriceStay] = useState(0);
  const [upsellValues, setUpsellValues] = useState({});
  const [userDetails, setUserDetails] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const { name, lastName, email, phone, message } = userDetails;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const increaseUpsellValue = (ups) => {
    if (!upsellValues) return;
    setUpsellValues((prevState) => ({
      ...prevState,
      [ups.id]: {
        ...prevState[ups.id],
        value:
          (prevState[ups.id] && prevState[ups.id]["value"]
            ? prevState[ups.id]["value"]
            : 0) + 1,
        icon: ups.icon,
        price: ups.price,
        name: ups.code,
        type: ups.type,
        id: ups.id,
      },
    }));
  };

  const decreaseUpsellValue = (ups) => {
    if (!upsellValues) return;
    setUpsellValues((prevState) => ({
      ...prevState,
      [ups.id]: {
        ...prevState[ups.id],
        value:
          (prevState[ups.id] && prevState[ups.id]["value"]
            ? prevState[ups.id]["value"]
            : 0) - 1,
        icon: ups.icon,
        price: ups.price,
        name: ups.code,
        type: ups.type,
        id: ups.id,
      },
    }));
  };
  const totalDays = useCallback(() => {
    var start = moment(new Date(arrivalDate), "YYYY-MM-DD");
    var end = moment(new Date(departureDate), "YYYY-MM-DD");
    return Math.abs(moment.duration(start.diff(end)).asDays());
  }, [arrivalDate, departureDate]);
  const totalHours = useCallback(() => {
    var start = moment(new Date(arrivalDate), "YYYY-MM-DD 02:00:ss");
    var end = moment(new Date(departureDate), "YYYY-MM-DD 11:00:ss");
    return Math.abs(moment.duration(start.diff(end)).asHours());
  }, [arrivalDate, departureDate]);

  const totalUpsell = useCallback(
    (item) => {
      let total = 0;
      if (item.value > 0) {
        let item_price = 0;
        switch (item.type) {
          case "stay":
            item_price = item.price;
            break;
          case "hour":
            item_price = item.price * totalHours();
            break;
          case "night_person":
            item_price = item.price * persons.adults * totalDays();
            break;
          default:
            item_price = item.price * totalDays();
            break;
        }
        total += item_price * item.value;
      }
      return total;
    },
    [persons.adults, totalDays, totalHours]
  );

  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const handleSubmit = async (stripe, elements) => {
    setIsLoading(true);
    if (paymentMethodId) {
      submitDetailsForm(
        upsellValues,
        params.id,
        userDetails,
        paymentMethodId,
        { arrivalDate, departureDate, persons },
        params.tenant,
        voucherCode
      )
        .then((response) => {
          setIsLoading(false);
          setIsBooked(true);
          console.log(response);
        })
        .catch((error) => {
          console.log("this is error:", error.response.data);
          setErrorMessage(error.response.data.message);
          setFormError(error.response.data.errors || {});
          setIsLoading(false);
          setPaymentMethodId(null);
        });
      return;
    }
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name: document.getElementById("inputCardholderName").value,
      },
    });

    if (error) {
      console.log("[error]", error);
      setIsLoading(false);
      setError(error.message);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      setPaymentMethodId(paymentMethod.id);
      submitDetailsForm(
        upsellValues,
        params.id,
        userDetails,
        paymentMethod.id,
        { arrivalDate, departureDate, persons },
        params.tenant,
        voucherCode
      )
        .then((response) => {
          setIsLoading(false);
          setIsBooked(true);
          console.log(response);
        })
        .catch((error) => {
          console.log("this is error:", error.response.data);
          setErrorMessage(error.response.data.message);
          setFormError(error.response.data.errors || {});
          setIsLoading(false);
          setPaymentMethodId(null);
        });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      alert(t("accept"));
      return;
    }
    if (formRef && formRef.current) {
      handleSubmit(stripe, elements);
    }
  };

  const getPriceOfStay = async () => {
    try {
      await axios
        .post(
          `${getTenantDomain(params.tenant)}/api/iframe/get-price/${params.id}`,
          {
            arrivalDate: moment(arrivalDate).format("YYYY-MM-DD"),
            departureDate: moment(departureDate).format("YYYY-MM-DD"),
            guests: persons.adults + persons.children,
          }
        )
        .then((res) => {
          setPriceStay(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    getPriceOfStay();
    let total = 0;
    Object.values(upsellValues).forEach((upsell) => {
      total += totalUpsell(upsell);
    });

    total += parseFloat(priceStay);

    if (voucherData) {
      console.log("voucherData", voucherData);
      if (voucherData.type == 2) {
        total -= (total * voucherData.value) / 100;
      } else {
        total -= voucherData.value;
      }
    }
    setTotal(total.toFixed(2));
  }, [
    total,
    totalUpsell,
    upsellValues,
    selectedProperty,
    voucherData,
    arrivalDate,
    departureDate,
    priceStay,
  ]);

  const minimumStayMap = minimumStayDates.reduce((acc, item) => {
    const key = Object.keys(item)[0];
    acc[key] = item[key];
    return acc;
  }, {});

  const renderDayContents = (day, date) => {
    if (
      moment(arrivalDate).format("YYYY-MM-DD") ==
      moment(date).format("YYYY-MM-DD")
    ) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const minStay = minimumStayMap[formattedDate];

      const tooltipText = `${minStay}-night minimum `;
      return <span title={minStay ? tooltipText : ""}>{getDate(date)}</span>;
    } else {
      return <span>{getDate(date)}</span>;
    }
  };

  const updateDepartureDisabledDates = (date = null) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const minStay = minimumStayMap[formattedDate];
      if (minStay) {
        const newDates = [];
        for (let i = 1; i < minStay; i++) {
          newDates.push(moment(date).add(i, "days").format("YYYY-MM-DD"));
        }

        dispatch(setDepartureDate(null));
        const IosFormat = newDates.map((date) => parseISO(date));
        setDepartureDisabledDates((prev) => [...prev, ...IosFormat]);
      } else {
        setDepartureDisabledDates(disabledDates);
      }
    } else {
      setDepartureDisabledDates(disabledDates);
    }
  };

  const isCheckoutDateDisabled = (date) => {
    if (!DisabledDates) {
      return true;
    }
    if (arrivalDate === "" || arrivalDate === null) {
      return false;
    }
    if (date < arrivalDate) {
      return true;
    }

    if (disabledDates.length === 0) {
      return false;
    }
    if (disabledDates[disabledDates.length - 1] < arrivalDate) {
      return true;
    }
    const firstDisabledDate = disabledDates.find((dateStr) => {
      return dateStr >= arrivalDate;
    });

    return firstDisabledDate ? date < firstDisabledDate : false;
  };

  const formRef = useRef();

  if (!stripe) {
    return <p>{t("loading")}</p>;
  }

  if (isBooked) {
    return <SuccessPage />;
  }

  return (
    <main>
      <Helmet>
        <link
          href={`${getTenantDomain(params.tenant)}/iframe/style.css`}
          rel='stylesheet'
        />
      </Helmet>
      <section className='reservationSection'>
        <div className='container'>
          <SearchBar disabledDates={disabledDates} />
          <div className='appartBookingDetailWrap'>
            <div className='colsHolder'>
              <div className='chCol chCol8'>
                <div className='appartSpecsWrap visible-xs hidden-md'>
                  <div className='imgHolder'>
                    <PropertyImage
                      width='auto'
                      height='200px'
                      property={selectedProperty}
                      images={selectedProperty?.gallery || []}
                      tenant={params.tenant}
                      thumbnails={true}
                    />
                  </div>
                  <div className='appartDescrTextWrap'>
                    <header className='appartHeader'>
                      <h3>{selectedProperty?.name}</h3>
                    </header>
                    <ul className='listUnstyled dateTimeInfo'>
                      <li>
                        <h4 className='text-upper'>{t("arrival")}</h4>
                        <DatePicker
                          placeholderText={t("arrival")}
                          className='formControl'
                          excludeDates={disabledDates}
                          renderDayContents={renderDayContents}
                          selected={arrivalDate}
                          onChange={(date) => {
                            if (date > departureDate) {
                              dispatch(setDepartureDate(null));
                            }
                            updateDepartureDisabledDates(date);
                            var isRangeValid =
                              disabledDates &&
                              disabledDates.length > 0 &&
                              !disabledDates.some(
                                (disabledDate) =>
                                  disabledDate > date &&
                                  departureDate &&
                                  disabledDate < departureDate
                              );

                            if (!disabledDates || disabledDates.length === 0) {
                              var isRangeValid = true;
                            }
                            if (!isRangeValid) {
                              MySwal.fire(
                                <p style={{ lineHeight: "2" }}>
                                  Selected range includes booked dates. Please
                                  select a different range
                                </p>,
                                null,
                                "error"
                              );
                              dispatch(setDepartureDate(null));
                            } else {
                              dispatch(setArrivalDate(date));
                            }
                          }}
                          selectsStart
                          startDate={arrivalDate}
                          endDate={departureDate}
                          monthsShown={1}
                          dateFormat='dd/MM/yyyy'
                          minDate={new Date()}
                        />
                      </li>
                      <li>
                        <h4 className='text-upper'>{t("departure")}</h4>
                        <DatePicker
                          placeholderText={t("departure")}
                          excludeDates={disabledDates}
                          filterDate={isCheckoutDateDisabled}
                          className='formControl'
                          monthsShown={1}
                          dateFormat='dd/MM/yyyy'
                          selected={departureDate}
                          onChange={(date) => {
                            var isRangeValid = !disabledDates.some(
                              (disabledDate) =>
                                disabledDate >= arrivalDate &&
                                disabledDate < date
                            );

                            if (!disabledDates || disabledDates.length === 0) {
                              isRangeValid = true;
                            }

                            if (!isRangeValid) {
                              MySwal.fire(
                                <p style={{ lineHeight: "2" }}>
                                  Selected range includes booked dates. Please
                                  select a different range
                                </p>,
                                null,
                                "error"
                              );
                            } else {
                              // If the range is valid, proceed to set the departure date
                              dispatch(setDepartureDate(date));
                            }
                          }}
                          selectsEnd
                          startDate={arrivalDate}
                          endDate={departureDate}
                          minDate={
                            arrivalDate
                              ? moment(arrivalDate).add(1, "days").toDate()
                              : new Date()
                          }
                        />
                      </li>

                      <li className='icnWrapList'>
                        <span className='icnAsses'>
                          <img src={unionIcon01} alt='imageDescription' />
                        </span>
                        <p>
                          {persons.adults} {t("adult")}, {persons.children}{" "}
                          {t("children")}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='custBookDetailsWrap'>
                  <header className='headingHead'>
                    <h2>{t("contact_details")}</h2>
                  </header>
                  <form className='bookingForm'>
                    <div className='colsHolder'>
                      <div className='chCol chCol6'>
                        <div className='formGroup'>
                          <input
                            onChange={handleChange}
                            name='name'
                            value={name}
                            type='text'
                            className='formControl'
                            placeholder={t("placeholder_name")}
                          />
                          {formError["userDetails.name"] && (
                            <p className='error-message'>
                              {formError["userDetails.name"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='chCol chCol6'>
                        <div className='formGroup'>
                          <input
                            onChange={handleChange}
                            value={lastName}
                            name='lastName'
                            type='text'
                            className='formControl'
                            placeholder={t("placeholder_last_name")}
                          />
                          {formError["userDetails.lastName"] && (
                            <p className='error-message'>
                              {formError["userDetails.lastName"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='chCol chCol6'>
                        <div className='formGroup'>
                          <input
                            onChange={handleChange}
                            value={email}
                            name='email'
                            type='text'
                            className='formControl'
                            placeholder={t("placeholder_email")}
                          />
                          {formError["userDetails.email"] && (
                            <p className='error-message'>
                              {formError["userDetails.email"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='chCol chCol6'>
                        <div className='formGroup'>
                          <input
                            onChange={handleChange}
                            value={phone}
                            name='phone'
                            type='text'
                            className='formControl'
                            placeholder={t("placeholder_phone")}
                          />
                          {formError["userDetails.phone"] && (
                            <p className='error-message'>
                              {formError["userDetails.phone"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='chCol chCol12'>
                        <div className='formGroup mbZero'>
                          <textarea
                            onChange={handleChange}
                            value={message}
                            name='message'
                            type='text'
                            className='formControl'
                            placeholder={t("placeholder_message")}
                          ></textarea>
                          {formError["userDetails.message"] && (
                            <p className='error-message'>
                              {formError["userDetails.message"][0]}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className='roomAcceriesWrap'>
                    <header className='headingHead'>
                      <h2>{t("additional_item")}</h2>
                    </header>
                    <ul className='asseriesDetails listUnstyled'>
                      {upsells
                        .filter((ups) => {
                          if (ups && ups.excluded_properties) {
                            if (typeof ups.excluded_properties === "string") {
                              return !ups.excluded_properties
                                .split(",")
                                .includes(params.id);
                            }
                            return true;
                          }
                          return true;
                        })
                        .map((ups, i) => (
                          <li key={i}>
                            <div className='asessDescrWrap'>
                              <div className='assesTextDescr'>
                                <span className='icnAsses'>
                                  <img src={unionIcon} alt='imageDescription' />
                                </span>
                                <div className='textWrap'>
                                  <h3>{ups.code}</h3>
                                  <p>
                                    <strong>
                                      {formatMoney(ups.price, 2, currency)} /{" "}
                                    </strong>{" "}
                                    {t("per")} {t(ups.type)}
                                  </p>
                                </div>
                              </div>
                              <div className='quantity'>
                                <input
                                  name={`val-${i}`}
                                  type='number'
                                  min='1'
                                  value={upsellValues[ups.id]?.value || 0}
                                />
                                <div className='quantity-nav'>
                                  <div
                                    className='quantity-button quantity-plus d-flex align-items-center justify-content-center'
                                    onClick={() => {
                                      if (
                                        upsellValues[ups.id]?.value >=
                                        (ups.is_multi_quantity === 1
                                          ? ups.quantity_limit
                                          : 1)
                                      ) {
                                        return;
                                      } else {
                                        increaseUpsellValue(ups);
                                      }
                                    }}
                                  >
                                    <FaPlus />
                                  </div>
                                  <div
                                    className='quantity-button quantity-minus d-flex align-items-center justify-content-center'
                                    onClick={() => {
                                      if (upsellValues[ups.id]?.value > 0) {
                                        decreaseUpsellValue(ups);
                                      } else {
                                        return;
                                      }
                                    }}
                                  >
                                    <FaMinus />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <header className='headingHead'>
                    <h2>{t("payment")}</h2>
                  </header>

                  {/* add voucher field */}
                  <VoucherInput
                    voucherCode={voucherCode}
                    setVoucherCode={setVoucherCode}
                    voucherData={voucherData}
                    setVoucherData={setVoucherData}
                  />
                  <Elements stripe={stripe}>
                    <CheckoutForm
                      formRef={formRef}
                      setElements={setElements}
                      setError={setError}
                      setStripe={setStripe}
                      error={error}
                      handleSubmit={handleSubmit}
                      isChecked={isChecked}
                      setChecked={setChecked}
                    />
                  </Elements>
                  {/* loop errorMessage and show here.  */}
                  {Array.isArray(errorMessage) &&
                    errorMessage.map((error, index) => {
                      return (
                        <div
                          className='alert alert-danger'
                          role='alert'
                          key={index}
                        >
                          {error}
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className='chCol chCol4'>
                <div className='appartSpecsWrap visible-xs hidden-md'>
                  <div className='appartDescrTextWrap'>
                    <header className='appartHeader'>
                      <h4>{t("selected_add")}</h4>
                    </header>
                    <ul className='listUnstyled appartQunatList'>
                      {Object.entries(upsellValues).map(([id, upsell]) =>
                        upsell.value === 0 ? null : (
                          <li>
                            <div className='icnDescrWrap'>
                              <span className='icnAsses'>
                                <img src={unionIcon01} alt='imageDescription' />
                              </span>
                              <p>
                                {upsell.name} {Number(upsell.value)}x
                              </p>
                            </div>
                            <strong className='appartPrice'>
                              {formatMoney(totalUpsell(upsell), 2, currency)}
                            </strong>
                          </li>
                        )
                      )}
                    </ul>
                    <div className='totalRow'>
                      <div className='icnDescrWrap'>
                        <p>{t("total")}</p>
                      </div>
                      <strong className='appartPrice'>
                        {formatMoney(total, 2, currency)}
                      </strong>
                    </div>
                    <div className='totalRow'>
                      <div className='alert-container'>
                        <FiAlertCircle className='feather-icon-alert-circle' />
                        <span className='alert-text'>
                          Please note: Additional fees like city tax may apply
                          after payment.
                        </span>
                      </div>
                    </div>
                    <div className='totalRow'>
                      {/* loop errorMessage and show here.  */}
                      {errorMessage && (
                        <div
                          className='alert alert-danger'
                          role='alert'
                          style={{ color: "red" }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </div>
                    <div className='submitBtn'>
                      <button
                        disabled={isLoading}
                        className='btnPrimary primaryBtnColor'
                        onClick={submitHandler}
                      >
                        {isLoading && <Spinner />}
                        {t("confirm")}
                      </button>
                    </div>
                  </div>
                </div>

                <div className='appartSpecsWrap hidden-xs stickycard'>
                  <div className='imgHolder'>
                    <PropertyImage
                      width='auto'
                      height='200px'
                      property={selectedProperty}
                      images={selectedProperty?.gallery || []}
                      tenant={params.tenant}
                      thumbnails={true}
                    />
                  </div>
                  <div className='appartDescrTextWrap'>
                    <header className='appartHeader'>
                      <h3>{selectedProperty?.name}</h3>
                    </header>
                    <ul className='listUnstyled dateTimeInfo'>
                      {/* <li>
                        <h4 className="text-upper">{t("arrival")}</h4>
                        <p>
                          {moment(new Date(arrivalDate)).format("DD/MM/YYYY")}
                        </p>
                      </li>
                      <li>
                        <h4 className="text-upper">{t("departure")}</h4>
                        <p>
                          {moment(new Date(departureDate)).format("DD/MM/YYYY")}
                        </p>
                      </li> */}

                      <li>
                        <h4 className='text-upper'>{t("arrival")}</h4>
                        <DatePicker
                          placeholderText={t("arrival")}
                          className='formControl'
                          excludeDates={disabledDates}
                          selected={arrivalDate}
                          renderDayContents={renderDayContents}
                          onChange={(date) => {
                            if (date > departureDate) {
                              dispatch(setDepartureDate(null));
                            }
                            updateDepartureDisabledDates(date);
                            var isRangeValid =
                              disabledDates &&
                              disabledDates.length > 0 &&
                              !disabledDates.some(
                                (disabledDate) =>
                                  disabledDate > date &&
                                  departureDate &&
                                  disabledDate < departureDate
                              );

                            if (!disabledDates || disabledDates.length === 0) {
                              var isRangeValid = true;
                            }
                            if (!isRangeValid) {
                              MySwal.fire(
                                <p style={{ lineHeight: "2" }}>
                                  Selected range includes booked dates. Please
                                  select a different range
                                </p>,
                                null,
                                "error"
                              );
                              dispatch(setDepartureDate(null));
                            } else {
                              dispatch(setArrivalDate(date));
                            }
                          }}
                          selectsStart
                          startDate={arrivalDate}
                          endDate={departureDate}
                          monthsShown={1}
                          dateFormat='dd/MM/yyyy'
                          minDate={new Date()}
                        />
                      </li>
                      <li>
                        <h4 className='text-upper'>{t("departure")}</h4>
                        <DatePicker
                          placeholderText={t("departure")}
                          excludeDates={departureDisabledDates}
                          filterDate={isCheckoutDateDisabled}
                          className='formControl'
                          monthsShown={1}
                          dateFormat='dd/MM/yyyy'
                          selected={departureDate}
                          onChange={(date) => {
                            var isRangeValid = !disabledDates.some(
                              (disabledDate) =>
                                disabledDate >= arrivalDate &&
                                disabledDate < date
                            );

                            if (!disabledDates || disabledDates.length === 0) {
                              isRangeValid = true;
                            }

                            if (!isRangeValid) {
                              MySwal.fire(
                                <p style={{ lineHeight: "2" }}>
                                  Selected range includes booked dates. Please
                                  select a different range
                                </p>,
                                null,
                                "error"
                              );
                            } else {
                              // If the range is valid, proceed to set the departure date
                              dispatch(setDepartureDate(date));
                            }
                          }}
                          selectsEnd
                          startDate={arrivalDate}
                          endDate={departureDate}
                          minDate={
                            arrivalDate
                              ? moment(arrivalDate).add(1, "days").toDate()
                              : new Date()
                          }
                        />
                      </li>

                      <li className='icnWrapList'>
                        <span className='icnAsses'>
                          <img src={unionIcon01} alt='imageDescription' />
                        </span>
                        <p>
                          {persons.adults} {t("adult")}, {persons.children}{" "}
                          {t("children")}
                        </p>
                      </li>
                    </ul>
                    <header className='appartHeader'>
                      <h4>{t("selected_add")}</h4>
                    </header>
                    <ul className='listUnstyled appartQunatList'>
                      {Object.entries(upsellValues).map(([id, upsell]) =>
                        upsell.value === 0 ? null : (
                          <li>
                            <div className='icnDescrWrap'>
                              <span className='icnAsses'>
                                <img src={unionIcon01} alt='imageDescription' />
                              </span>
                              <p>
                                {upsell.name} {Number(upsell.value)}x
                              </p>
                            </div>
                            <strong className='appartPrice'>
                              {formatMoney(totalUpsell(upsell), 2, currency)}
                            </strong>
                          </li>
                        )
                      )}
                    </ul>
                    <div className='totalRow'>
                      <div className='icnDescrWrap'>
                        <p>{t("total")}</p>
                      </div>
                      <strong className='appartPrice'>
                        {formatMoney(total, 2, currency)}
                      </strong>
                    </div>
                    <div className='totalRow'>
                      <div class='alert-container'>
                        <FiAlertCircle className='feather-icon-alert-circle' />
                        <span class='alert-text'>
                          Please note: Additional fees like city tax may apply
                          after payment.
                        </span>
                      </div>
                    </div>
                    <div className='totalRow'>
                      {errorMessage && (
                        <div
                          className='alert alert-danger'
                          role='alert'
                          style={{ color: "red" }}
                        >
                          {errorMessage}
                        </div>
                      )}
                    </div>
                    <div className='submitBtn'>
                      <button
                        disabled={isLoading}
                        className='btnPrimary primaryBtnColor'
                        onClick={submitHandler}
                      >
                        {isLoading && <Spinner />}
                        {t("confirm")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default FormPage;
