export const getTenantDomain = (tenant: string) => {
  // if (process.env.NODE_ENV === 'production') {
  //   // if (process.env.BUILD_TYPE === 'staging') {
  //     // return `https://${tenant}.rentalls.tech`
  //   // }else {
  //     return `https://${tenant}.rentalls.com`
  //   // }
  // }
  return `https://${tenant}.rentalls.tech`;
};

export const getNameFromLocale = (json: any, locale: any) => {
  try {
    // parse json if it's a string
    let strings = json;
    if (typeof json === "string") {
      strings = JSON.parse(json);
    }
    if (strings[locale]) {
      return strings[locale];
    }
    return strings[Object.keys(strings)[0]];
  } catch (err) {
    return json;
  }
};

export const formatMySQLDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0"); // months are 0-based in JS
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return (
    year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  );
};

export const isJson = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const roundNumber = (number: any, precision: any) => {
  precision = Math.abs(parseInt(precision)) || 0;
  var multiplier = Math.pow(10, precision);
  return Math.round(number * multiplier) / multiplier;
};

export const formatMoney = (
  number: any,
  decimal_place: any,
  currency: any = null
) => {
  if (currency === null) {
    currency = {
      code: "EUR",
      name: "Euros",
      symbol: "€",
      symbol_position: "after",
    };
  } else {
    currency = { ...currency, symbol_position: "after" };
  }

  let symbol = currency.symbol;
  let symbol_position = currency.symbol_position;
  if (decimal_place === undefined) decimal_place = 2;

  return (
    (symbol_position === "before" ? symbol : "") +
    roundNumber(number, decimal_place) +
    (symbol_position === "after" ? symbol : "")
  );
};
